<template>
  <b-row>
    <b-col class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch mb-1">
      <b-card 
        class="radius-card-transaction w-100 h-100" 
        body-class="bg-light-primary card-balance-transaction"
      >
        <div>
          <div class="pl-1 pt-2">
            <h5>{{$t('creator.balanceWithdraw')}} 
              <b-icon :icon="viewEye ? 'eye-fill' : 'eye-slash-fill'" class="cursor-pointer" @click="changeEye"/>
            </h5>
          </div>
          <div class="d-flex pl-1">
            <h2 class="mr-1 text-body-price">
              $ {{ viewEye ? numberFormat(total_ready,2) : 
                numberFormat(total_ready,2).toString().replace(/[0-9,.]/g, '*')}} USD
            </h2>
          </div>
        </div>  
        <div class="bg-light card-transaction-retired pl-1 p-1">
          <span>
            $ {{ viewEye ? numberFormat(total_balance, 2) +' USD '+ $t('dashboard.totalWon') : 
              numberFormat(total_balance,2).toString().replace(/[0-9,.]/g, '*') +' USD '+ $t("dashboard.totalWon")}}
          </span>
        </div>
      </b-card>
    </b-col>
    <b-col class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch mb-1">
        <b-card class="overflow-hidden card-shadow radius-card-transaction h-100">
          <div class="background-left-proposal default-color-background-tools">
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <h6 class="title-tools">{{$t('creator.activePurchases')}}</h6>
            <h2>{{ active_sales }}</h2>
            <p class="text-muted m-0">{{finished_sales}} {{$t('creator.completedPurchases')}}</p>
          </div>
        </b-card>
      </b-col>
    <b-col class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch mb-1">
        <b-card class="overflow-hidden card-shadow radius-card-transaction h-100">
          <div class="background-left-proposal default-color-background-orders">
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <h6 class="title-orders">{{$t('creator.currentSales')}}</h6>
            <h2>{{ new_collabs }}</h2>
            <p class="text-muted m-0">{{ active_proposals }} {{$t('creator.completedSales')}}</p>
          </div>
        </b-card>
      </b-col>
    <b-col class="col-12 col-lg-6 col-xl-3 mb-1" v-for="(card, index) in cards_works" :key="index">
      <card-work
        v-if="getPlan() === 'Free'" 
        :data="card" 
        :icon_hidden="true"
        class="h-100 d-flex align-items-stretch"
        @click_action="redirectAction"
      />

      <card-go-pro v-if="getPlan() !== 'Free'" class="d-flex align-items-stretch w-100"
        :cardSize="'card-go-transaction'"
        :textBadge="$t('creator.creatorPro')"
        :textInfo="$t('creator.textCreatorPro')"
        :img="imgNetwork('bm_influencer_pro')"
        :img_go="'img-go-transaction'"
        :position_img="'position-img-transaction'"
        :typeCard="'cardTransaction'"
        :disabledButton="true"
      />
    </b-col>

    <b-col class="col-12 mt-1">
      <b-card class="table-responsive" id="table-transactions-card">
        <b-col class="d-flex justify-content-between align-items-center flex-wrap p-1">
          <div class="container-mode-views">
            <div class="color-gray-button responsive-button" v-if="checked_transactions.length !== 0">
              <span class="ml-2">{{$t('creator.selected_transactions')}}: {{checked_transactions.length}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">
            <b-button 
              variant="outline-secondary" 
              :disabled="checked_transactions.length === 0"
              class="mb-1 mb-sm-0"
              @click="exportTransactions()"
            >
              <div class="d-flex align-items-center justify-content-around">
                  <feather-icon icon="ShareIcon"></feather-icon>
                  {{$t('campaigns.export')}}
                </div>
            </b-button>
            <b-button 
              :disabled="proposals_paid.length === 0"
              variant="outline-secondary"
              class="button-filters mb-1 mb-sm-0"
              @click="show_sider_filter = true"
            >
              <feather-icon icon="FilterIcon"></feather-icon>
              {{$t('creator.filterButton')}}
            </b-button>
            <b-button 
            :disabled="filters_layout.length === 0"
              variant="outline-secondary"
              class="button-filters mb-1 mb-sm-0"
              @click="getTransactionsFunction()"
            >
              <feather-icon icon="Trash2Icon"/>
              {{$t('creator.clearFilter')}}
            </b-button>
          </div>
        </b-col>         
      </b-card>
    </b-col>
    <b-col>
      <div class="scrollable-table">
        <div v-if="proposals_paid.length > 0 && format_data.length > 0">
          <b-table
            :items="format_data"
            id="table-transactions"
            :fields="$t(`creator.table_proposals.transactions`)"
            head-variant="light"
            td-class="d-flex"
            :current-page="current_page"
            :per-page="per_page"       
          >
            <template #head(id)>
              <b-form-checkbox v-model="selected_all"></b-form-checkbox>
            </template>
            <template #cell(id)="data">
              <div class="w-100 mb-1 position-relative">
                <div class="d-flex justify-content-between position-absolute">
                  <b-form-checkbox
                    class="checkbox-table-transactions d-none position-absolute-checkbox-searcher"
                    v-model="checked_transactions"
                    :value="data.item.id.uuid"
                    :ref="`checkbox_table_${data.item.id.id}`"
                  >
                  </b-form-checkbox>
                  <h6 class="id-table-transactions" :ref="`id_table_${data.item.id.id}`">{{data.item.id.id}}</h6>
                </div>
              </div>
            </template>
            <template #cell(title)="data">
              <div class="d-flex align-items-center">
                  <b-avatar  v-if="data.item.main_image"
                    class="mr-1"
                    rounded
                    button
                    :src="data.item.main_image"
                    variant="dark">
                  </b-avatar>
                  <b-avatar  v-else
                    class="mr-1"
                    rounded
                    button
                    variant="dark">
                  </b-avatar>
                <div>
                  <span class="name-transactions">{{data.item.title}}</span>
                  <span v-if="data.item.work_type" class="text-muted m-0">
                    {{$t(`creator.type_of_work`).find((type) => type.value === data.item.work_type).text}}
                  </span>
                </div>
              </div>
            </template>
            <template #cell(type)="data">
              <b-badge pill class="type-transactions">
              {{ $t(`creator.type_transactions.${data.item.type}`) }}
              </b-badge>
            </template>
            <template
              #cell(amount)="data"
            >
                $ {{ numberFormat(data.item.amount, true) }}
            </template>
            
            <template
              #cell(status)="data"
            >
              <b-badge pill :variant="getVariantStatus(data.item.status)" :class="getVariantStatus(data.item.status)">
                {{statusText(data.item.status)}}
              </b-badge>
            </template>
            <template
              #cell(days_payout)="data"
            >
              <b-badge pill class="badge-payout">
                {{ daysremainingforwithdrawal(data.item)}} 
              </b-badge>
            </template>
            <template
              #cell(paid_at)="data"
            >
                {{ getDatev2(new Date(data.item.paid_at)) }} 
            </template>
            <template #cell(invoice)="data">
              <div class="d-flex">
                <b-button variant="light" v-if="data.item.metadata.invoice_pdf" @click="downloadFile(data.item.metadata.invoice_pdf)">PDF</b-button>
                <b-button variant="light" class="ml-1" v-if="data.item.metadata.hosted_invoice_url" @click="openInvoiceUrl(data.item.metadata.hosted_invoice_url)">{{ $t('profile.quickView') }}</b-button>
                <span v-else> --- </span>
              </div>
            </template>
          </b-table>
          <b-pagination 
            v-model="current_page"
            :total-rows="format_data.length" 
            :per-page="per_page"
            class="d-flex justify-content-center"
          ></b-pagination>
        </div>
        <template v-else>
          <no-Results
            :title= "$t('creator.messageNoResultsTransaction')"
            :show_button= "false"
          />
        </template>
      </div>
    </b-col>    
    <div class="container-loader-searcher loader-table-cards" v-if="!loader_transaction">
      <b-spinner class="spinner-table-searcher"></b-spinner>
    </div>
    <sider-filters
      class="open-sidebar-search"
      @close_siderbar_filter="closeSiderFilter"
      :show_sider_filter="show_sider_filter"
      :filters_layout="filters_layout"
      @update_transaction="getFormatTable"
      v-if="show_sider_filter"
    />
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BFormCheckbox,
  BButton,
  BSpinner,
  BPagination,
} from 'bootstrap-vue';
import { getDatev2 } from '@/libs/utils/times';
import { downloadFile } from '@/libs/utils/others';
import { getUserData } from '@/libs/utils/user';
import { getUrlCategory, getAssetUrl } from '@/libs/utils/urls';
import { numberFormat } from '@/libs/utils/formats';
import proposals_services from '@/services/proposals';
import { getTransactions } from '@/services/finance';
import noResults from '@/views/pages/proposals/stepper/noResults.vue';
import siderFilters from "@/views/pages/proposals/stepper/siderFilters.vue";
import service from "@/services/dashboard";
import cardGoPro from '@/views/components/proposals/cardGoPro.vue';
export default {
  name: 'orderProposals',
  components: {
    cardGoPro,
    BSpinner,
    noResults,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BCard,
    BFormCheckbox,
    BButton,
    siderFilters,
    cardWork: () => import('@/views/pages/proposals/cardWork.vue'),
  },
  data() {
    return {
      downloadFile,
      getDatev2,
      numberFormat,
      format_data: [],
      loader_transaction: false,
      fields: [
        {
          "key": "proposal",
          "label": "Propuesta",
          "sortable": true
        },
        {
          "key": "status",
          "label": "Estatus",
          "sortable": true
        }
      ],
      cards_works: [
        {
          title: this.$t('creator.upgradeText'),
          icon: 'plusIcon',
          text: this.$t('creator.textCardWork'),
          text_action: this.$t('creator.btnText'),
          background_left: 'linear-gradient(0deg, #A24EF0, #994EFF)',
          backgrounds: {
            container: 'linear-gradient(0deg, #A24EF0, #994EFF)',
            container_hover: '#a24ef038',
            filter_svg: 'invert(55%) sepia(95%) saturate(2200%) hue-rotate(270deg) brightness(50%) contrast(100%)',
            selected: 'linear-gradient(0deg, #A24EF0, #994EFF)',
          },
          card_action: 'redirectMembership'
        },
      ],
      proposals_paid: [],
      checked_transactions: [],
      selected_all: false,
      show_sider_filter: false,
      filters_layout: [],
      ready_balance: 0,
      viewEye: false,
      active_sales: 0,
      finished_sales: 0,
      new_collabs: 0,
      active_proposals: 0,
      current_page: 1,
      per_page: 10,
      waiting_balance: 0,
      total_ready: 0,
      program_withdrawal_balance: 0,
      total_balance: 0,
    }
  },
  created() {
    this.getTransactionsFunction()
    this.getBalance()
    this.getPlan()
    this.getMyWorkSummary();
    this.$emit('checked_transactions', [])
  },
  methods: {
    openInvoiceUrl(url) {
      window.open(url, '_blank')
    },
    redirectAction(method) {
      this[method]();
    },
    redirectMembership() {
      this.$router.push({name: 'pages-account-setting', params: { section: 'membership' } })
    },
    daysremainingforwithdrawalAction(transaction) {
      const {paid_at, days_payout} = transaction;
      const pay_day = new Date(paid_at);
      const date_withdrawal = new Date(pay_day);
      date_withdrawal.setDate(pay_day.getDate() + days_payout);

      const current_date = new Date();
      const difference_days = Math.ceil((date_withdrawal - current_date) / (1000 * 60 * 60 * 24));

      return difference_days;
    },
    daysremainingforwithdrawal(transaction) {
      const { status } = transaction;
      const difference_days = this.daysremainingforwithdrawalAction(transaction);

      if (status === 'withdrawal') return this.$t('creator.mountWithdrawn')
      if (status === 'rejected') return this.$t('creator.cancelStatus')
      if (status === 'program_withdrawal' || status === 'unpaid') return 'N / A'

      if (difference_days <= 0 && status === 'paid') {
        return this.$t('creator.readyWithdrawn')
      } else {
        return  `${difference_days} ${this.$t('creator.daysWithdrawal')}`;
      }
    },
    statusText(status) {
      if (status === 'paid') return this.$t('creator.balanceWithdraw')
      if (status === 'unpaid') return this.$t('creator.noPaid')
      if (status === 'program_withdrawal') return this.$t('creator.scheduledWithdrawal')
      if (status === 'withdrawal') return this.$t('creator.mountWithdrawn')
      if (status === 'rejected') return this.$t('creator.cancelStatus')
    },

    referenceMember() {
      this.$router.push({ name: 'pages-account-setting', params: { section: 'membership' }})
    },
    getPlan() {
      return getUserData().current_plan.name
    },
    imgNetwork(category) {
      return getUrlCategory(category)
    },
    changeEye() {
      this.viewEye = !this.viewEye
    },
    getBalance() {
      service.getBalance().then(response => {
        this.total_balance = response.total_balance
        this.ready_balance = response.ready_balance;
        this.waiting_balance = response.waiting_balance;
        this.program_withdrawal_balance = response.program_withdrawal_balance
        this.total_ready = this.ready_balance + this.waiting_balance + this.program_withdrawal_balance;
      });
    },

    getMyWorkSummary() {
      proposals_services.myWorkSummary().then(response => {
        this.active_sales = response.active_sales;
        this.finished_sales = response.finished_sales;
        this.new_collabs = response.new_collabs;
        this.active_proposals = response.active_proposals;
      })
    },
    getTransactionsFunction() {
      this.loader_transaction = false
      this.filters_layout = []
      getTransactions().then(response => {
        this.checked_transactions = []
        this.proposals_paid = response.results
        this.loader_transaction = true
        this.getFormatTable(this.proposals_paid)
      })
    },
    closeSiderFilter() {
      this.show_sider_filter = false
    },
    getFormatTable(proposals, filters) {
      if (filters !== undefined) {
        this.filters_layout = filters
      }
      this.format_data = []
      proposals.map((item, index) => {
        this.format_data.push(
          {
            id: {id: index + 1, uuid: item.uuid},
            title: item.name,
            type: item.type,
            amount: item.amount,
            paid_at: item.paid_at,
            status: item.status,
            work_type: item.metadata && item.metadata.work_type ? item.metadata.work_type : '',
            main_image: item.metadata && item.metadata.main_image ? getAssetUrl(item.metadata.main_image) : (item.casting_call ? item.casting_call.cover_url : ''),
            days_payout: item.days_payout,
            metadata: item.metadata ?? {},
          });
      });
    },
    exportTransactions() {
      const rows = [[this.$t('creator.concept'), this.$t('creator.type'), this.$t('creator.amount'), this.$t('creator.state'), this.$t('creator.paidAt')]];
      const exportable = []
      this.checked_transactions.forEach(uuid => {
        const transaction = this.proposals_paid.find(tran => tran.uuid === uuid)
        if (transaction) exportable.push(transaction)
      })
      exportable.forEach(transaction => {
        rows.push([
          transaction.name,
          this.$t(`creator.type_transactions.${transaction.type}`),
          `$${transaction.amount.toLocaleString('en')}`,
          this.$t(`creator.status_transaction.${transaction.status}`),
          getDatev2(new Date(transaction.paid_at)),
        ])
      })
      this.downloadCsv(rows, "Transacciones BrandMe.csv")
    },
    downloadCsv(rows, name) {
      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    checkedAll() {
      this.checked_transactions = [];
      let in_array = 0;
      this.proposals_paid.forEach((item, index) => {
        if ((in_array < this.per_page) && (index <= (this.per_page * this.current_page) && index >= (this.per_page * (this.current_page - 1)))) {
          this.checked_transactions.push(item.uuid)
          in_array += 1
        }
      });
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.proposals_paid.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              
              checkbox.$el.classList.remove('d-none');
              checkbox.$el.classList.remove('checkbox-table-transactions');
            }
            
            if (idTable) {
              idTable.classList.add('d-none');
            }
          });
        }
      } else {
        for (let i = 1; i <= this.proposals_paid.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              
              checkbox.$el.classList.add('d-none');
              checkbox.$el.classList.add('checkbox-table-transactions');
            }
            
            if (idTable) {
              idTable.classList.remove('d-none');
            }
          });
        }
      }
    },
    getVariantStatus(status) {
      if (status === 'paid') return 'light-info'
      if (status === 'rejected') return 'light-danger'
      if (status === 'unpaid') return 'light-warning'
      if (status === 'withdrawal' || status === 'program_withdrawal') return 'light-success'
      return 'light-secondary'
    },
    uuidsReadyToWithdraw(checked_transactions) {
      return checked_transactions.filter(uuid => {
        const transaction = this.proposals_paid.find(tran => tran.uuid === uuid)
        return transaction?.status === 'paid' && this.daysremainingforwithdrawalAction(transaction) <= 0
      })
    }
  },
  watch: {
    checked_transactions(val) {
      this.checkCheckboxClasses(val);
      if (this.checked_transactions.length === 0) this.selected_all = false
      else if (this.checked_transactions.length === this.proposals_paid.length) this.selected_all = true
      this.$emit('checked_transactions', this.uuidsReadyToWithdraw(val))
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_transactions = [];
    },
    current_page() {
      this.selected_all = null;
    }
  }
}
</script>
<style>
.badge-payout {
  background-color: #FCD147;
  color: black;
}
.position-img-transaction {
  top: -5.2em;
}
.img-go-transaction {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-bottom: 1em;
  border-radius: 100%;
}
.card-go-transaction {
  border-radius: 1em;
  display: flex;
  overflow: hidden;
  width: 100%;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 14em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.card-transaction-retired {
  display: flex;
  align-items: center;
  border-radius: 0 0 1em 1em;
  color: #000000;
}
.radius-card-transaction {
  border-radius: 1em !important;
}
.name-transactions {
  color: #55a6c4 !important;
  font-weight: bold !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.status-transactions {
  background-color: #212529 !important;
}
.type-transactions {
  background-color: rgba(19,33,68,.1) !important;
  color: #495057 !important;
  font-weight: bold !important;
}
#table-transactions-card .card-body {
  padding: 0;
}
.table-responsive {
  overflow: visible;
}
#table-transactions td {
  background-color: white;
}
#table-transactions tr {
  background-color: white;
}
#table-transactions tr:hover {
  background-color: #EFF3F6;
  cursor: pointer;
  transition: all 100ms;
}
.thead-light th {
  background-color: white !important;
}
.checkbox-table-transactions {
  display: none !important;
}
#table-transactions tr:hover .checkbox-table-transactions {
  display: block !important;
}
#table-transactions tr:hover .id-table-transactions {
  display: none;
}
.button-filters {
  margin-left: 5px;
}
.button-filters:focus {
  margin-left: 5px;
  background-color: transparent !important;
}
.open-sidebar-search > .b-sidebar-right {
animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
.card-balance-transaction {
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 1em !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
</style>
<style scoped>
.card-shadow {
  width: 100%;
}
</style>