<template>
  <b-row>
    <b-col>
      <div class="d-flex justify-content-between flex-wrap ml-1 my-1 my-xl-0">
        <h2 class="col-12 p-0 col-sm text-center text-sm-left">{{ $t('dashboard.collaborations') }}</h2>
        <b-button v-if="active_tab === 1"
          class="blue-button-proposals col-12 col-sm-auto"  
          variant="blue-button-proposals"
          @click="redirectOption()"
        >{{ $t("dashboard.createProposal") }}</b-button>

        <!-- @click="collectMoney()"
        :disabled="transactions_ready_withdraw.length === 0" -->
        <b-button v-if="active_tab === 4"
            class="blue-button col-12 col-sm-auto"  
            variant="blue-button"
            disabled
          >{{ $t("dashboard.collectMoney")}} 
        
          <span v-if="transactions_ready_withdraw.length > 0">({{ transactions_ready_withdraw.length }})</span>
        </b-button>

        <div v-if="active_tab === 5" class="p-0 col-12 col-sm-auto">
          <b-button v-if="!ready_profile"
            class="blue-button-proposals px-0 col-12 col-sm"  
            variant="blue-button-proposals"
            @click="$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})"
          >{{ $t("dashboard.createProfile")}}</b-button>

          <b-button v-else
            class="blue-button col-12 col-sm"  
            variant="blue-button"
            @click="redirectProfilePublic()"
          >{{ $t("creator.goPublicProfile")}}</b-button>
        </div> 
      </div>
      <b-tabs content-class="mt-3" v-model="active_tab">
        <b-tab :title="$t('creator.desk')" title-link-class="custom-tab" @click="$router.push({name: 'dashboard-analytics'})"></b-tab>
        <b-tab :title="$t('creator.myProposals')" title-link-class="custom-tab" :active="$route.params.section === 'proposals'"
          @click="$router.push({ name: 'my_work', params: { section: 'proposals' } }).catch(() => {})">
          <my-proposals/>
        </b-tab>
        <b-tab :title="$t('creator.myPitches')" title-link-class="custom-tab" :active="$route.params.section === 'pitches'"
          @click="$router.push({ name: 'my_work', params: { section: 'pitches' } }).catch(() => {})">
          <pitches />
        </b-tab>

        <b-tab :title="$t('creator.myOrders')" title-link-class="custom-tab" :active="$route.params.section === 'orders'"
          @click="$router.push({ name: 'my_work', params: { section: 'orders' } }).catch(() => {})">
          <order-proposals v-if="active_tab === 3"/>
        </b-tab>

        <b-tab :title="$t('creator.myTransaction')" title-link-class="custom-tab" :active="$route.params.section === 'transactions'"
          @click="$router.push({ name: 'my_work', params: { section: 'transactions' } }).catch(() => {})">
          <transactions-proposals v-if="active_tab === 4" @checked_transactions="transactionSelected" ref="transactions_proposals_component"/>
        </b-tab>

        <b-tab :title="$t('creator.myNetworks')" title-link-class="custom-tab" :active="$route.params.section === 'networks'"
          @click="$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})">
          <profile-networks v-if="active_tab === 5"></profile-networks>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue';
import myProposals from '@/views/pages/proposals/myProposals.vue';
import profileNetworks from '@/views/pages/proposals/profileNetworks.vue';
import orderProposals from '../proposals/orders.vue'
import transactionsProposals from '../proposals/transactions.vue'
import pitches from '@/views/pages/casting-calls/pitches.vue';
import ResendEmail from '@core/layouts/layout-horizontal/ResendEmail.vue';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { getUserData } from '@/libs/utils/user';
import { requestBalance } from '@/services/finance';

export default {
  name: 'myWork',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    myProposals,
    profileNetworks,
    orderProposals,
    transactionsProposals,
    pitches,
  },
  data() {
    return {
      create_proposal: getUserData().meta_info,
      active_tab: 1,
      groupRol: 'creator',
      ready_profile: false,
      transactions_ready_withdraw: [],
    }
  },
  created () {
    const data_user = JSON.parse(localStorage.getItem('userData'));
    this.groupRol = data_user.main_group.name;
    if (this.groupRol !== 'creator') this.$router.push({name: 'auth-login',})
    this.ready_profile = data_user.meta_info && !!data_user.meta_info.vanity_name
    this.meta_info = data_user.meta_info 
    this.email = data_user.email
    this.is_verified = data_user.is_verified
    this.user_data = data_user
  },
  methods: {
    transactionSelected(data) {
      this.transactions_ready_withdraw = data;
    },
    redirectOption() {
      if (this.create_proposal) {
        this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublicProposals'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            }
          })
        })
      }
    },
    collectMoney() {
      if (this.user_data && !this.is_verified) {
        this.$toast.error({
          component: ResendEmail,
          position:"top-center",
          props:{
            userData: {
              email: this.email
            },
            is_verified: this.is_verified
          }
        })
      } else {
        requestBalance({transactions_uuid: this.transactions_ready_withdraw}).then((response) => {
          if (response.status === 'OK') {

            this.$refs.transactions_proposals_component.getTransactionsFunction()
            this.transactions_ready_withdraw = []

            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('creator.requestCollect'),
                  text: this.$t('creator.yourRequest'),
                  icon: 'DollarSignIcon',
                  variant: 'success',
                }
              })
            })
          }
        }).catch(() => {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('search.check_influencer_title'),
                text: this.$t('creator.noTransactionSucccess'),
                icon: 'XIcon',
                variant: 'danger',
              }
            })
          })
        })
      }
    },
    redirectProfilePublic() {
      const data_user = JSON.parse(localStorage.getItem('userData'));
      const vanity_name = data_user.meta_info.vanity_name
      window.open(`/@${vanity_name}`, '_blank')
    },
  },
}
</script>
<style scoped>
.blue-button-proposals {
  background-color: #0096fe !important;
  border: 0 !important;
  color: white !important;
  width: 353px;
} 
.blue-button-proposals:hover {
  background-color:rgb(77, 182, 249) !important;
  box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px !important;  
  color: white !important;
  width: 353px;
}
.blue-button-proposals:focus {
  background-color: #0096fe !important;
  color: white !important;
  width: 353px;
}
.button-proposal {
  align-items: center;
  background-color: #55a6c4 !important;
  border:none;
}
.button-proposal:focus {
  background-color: #55a6c4 !important;
}
.alert-pop {
  background: linear-gradient(to left bottom, #7cd6af, #3e7ba0);
  cursor: pointer;
}
.title-pop {
  color: white;
  font-weight: bold;
}
.subtitle-pop {
  color: white;
}
</style>
<style>
.custom-tab:not(.active) {
  color: #8d8d9d8f ;
}
</style>